.side-bar {
    width: 100%;
    background-color: #fafafa;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    z-index: 2;
    top: 0;
    height: 50px;
}

.menu-sidebar {
    width: 100%;
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: auto;
    &::-webkit-scrollbar-track{
        background-color: #F5F5F5;
        border-radius: 10px;
    }

    &::-webkit-scrollbar{
        width: 10px;
        background-color: #F5F5F5;
        height: 5px;
    }

    &::-webkit-scrollbar-thumb{
        border-radius: 10px;
        background-image: -webkit-gradient(linear,
                                        left bottom,
                                        left top,
                                        color-stop(0.44, rgb(122,153,217)),
                                        color-stop(0.72, rgb(73,125,189)),
                                        color-stop(0.86, rgb(28,58,148)));
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 28px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 17px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.container-toggle-logout {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: gray;
    span {
        margin-bottom: 5px;
        font-size: 17px;
    }
    .swagger-title-toggle {
        margin-right: 5px;
    }
}
.button-logout {
    background: transparent;
    border: none;
    color: #6b3e3eb8;
    font-size: 16px;
    font-weight: 800;
    outline: none;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.div-select {
    width:250px;
    overflow:hidden;
}
  
.div-select select {
    background: #354880;
    background-position: 205px center;
    width: 250px;
    height:30px;
    font-family:Arial, Helvetica, sans-serif;
    font-size:16px;
    padding-left: 10px;
    color:#fff;
    text-indent: 0.01px;
    text-overflow: "";    
    select::-ms-expand {display: none;}
    option {
        width: 270px;
    }
}
.spin-loading {
    width: 100%;
    height: 150vh;
    align-content: center;
    align-items: center;
    justify-content: center;
    background-color: rgba(169, 177, 184, 0.103);
}

.loading-spin {
    position: absolute!important;
    top: 30%;
    left: 40%;
}